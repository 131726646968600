.SQLBox {
  @apply relative bg-pri-gray-50 p-4 mt-2;
  list-style: none;
}

.list {
  list-style: none;
}

.listItem {
  @apply mb-4 tracking-wide;

  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  color: var(--sec-purple-700);
  font-size: 14px;
}

.SQLBox ol li p:first-child {
  @apply text-pri-gray-500;
}

.SQLBox ol li p:not(first-child) {
  @apply text-sec-purple-700;
}
